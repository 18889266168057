<script>
export default {
  props: {
    user: Object,
  },
  data: function() {
    return {};
  },
  computed: {
    state() {
      let { isActive, isOnline } = this.user;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },

    cityConnected() {
      let { roomTemp, city } = this.user;
      return roomTemp?.cityName || city;
    },
    officeConnected() {
      let { roomTemp, office } = this.user;
      return roomTemp?.officeName || office;
    },
    roomConnected() {
      let { roomTemp, room } = this.user;
      return roomTemp?.roomName || room;
    },
  },
};
</script>

<template>
  <div class="modelCardDuplicate">
    <div class="modelCardDuplicate__wrapper">
      <div class="modelCardDuplicate__avatarContainer " :stateAvatar="state">
        <Avatar class="modelCardDuplicate__avatar" :user="user" />
        <span class="modelCardDuplicate__statusCircle" :stateAvatar="state"></span>
      </div>
      <div class="modelCardDuplicate__userData">
        <span class="modelCardDuplicate__name">{{ user.artisticName }}</span>
        <div class="modelCardDuplicate__info">
          <span>{{ cityConnected + " - " + officeConnected }}</span>
          <span v-if="roomConnected">{{ " - " + roomConnected }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.modelCardDuplicate {
  width: 90%;
  &__wrapper {
    @include Flex(row, space-between);
    flex-wrap: wrap;
    padding: 3px 12px;
    background: #f3f3f3;
    border-radius: 27px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background: rgba(242, 189, 189, 0.15);
      transition: 0.3s ease-in-out;
    }
  }
  &__avatarContainer {
    position: relative;
    border: 2px solid $alto;
    border-radius: 50%;
    cursor: pointer;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 33px;
    height: 33px;
    border: 1px solid $white;
  }
  &__statusCircle {
    position: absolute;
    width: 8px;
    height: 8px;
    right: 1px;
    bottom: 1px;
    border: 1px solid $white;
    border-radius: 50%;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__userData {
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    margin: 0 $mpadding;
    max-width: 142px;
    margin: 0 $mpadding;
    text-overflow: ellipsis;
    overflow: auto;
  }
  &__name {
    max-width: 116px;
    font-size: 0.95em;
    color: black;
    text-overflow: ellipsis;
  }
  &__info {
    font-size: 0.85em;
    color: $chicago;
  }
  @media screen and (min-width: $tablet-width) {
    &__userData {
      max-width: 100%;
    }
    &__wrapper {
      flex-wrap: nowrap;
    }
  }
}
</style>
